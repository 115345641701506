import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SeoPage from '../components/SEO/SeoPage';
import SliceZone from '../components/SliceZone';

const PageTemplate = props => {
    const { data } = props
    const { prismicPage } = data  
  return (
    <Layout>
      <SeoPage
        title={`${prismicPage.data.pagename.text}`}
      />
       <main className="m-2 md:mx-12 ml:mx-24">
      
      <h1 className="text-center">{prismicPage.data.pagename.text}</h1>
      <div dangerouslySetInnerHTML={{ __html: prismicPage.data.content.text }}></div>
      <SliceZone allSlices={prismicPage.data.body} />
    
      </main>
    </Layout>
  );
};
export const query = graphql`
  query pageQuery(
    $slug: String
  ) {
    prismicPage(uid: {eq: $slug}) {
        data {
          pagename {
            text
            html
          }
          content {
            text
            html
          }
          body {
            ... on PrismicPageDataBodyInsertForm {
              id
              slice_type
              primary {
                form_type
              }
            }
            ... on PrismicPageDataBodyPaperform {
              id
              slice_type
              primary {
                formslug {
                  text
                }
              }
            }
          }
        }
      }
  }
`;

export default PageTemplate;
